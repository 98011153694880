<template>
  <div id="#wrapper" class="layout cms-layout">
    <!-- Content -->
    <section id="content" v-if="components.content">
      <div class="content-wrap pt-0">
        <component v-for="(component, index) in components.content" :key="index + '-' + currentLanguageCode" :is="$cms.components.getComponent(component)" v-bind="$cms.components.getComponentProps(component)" @hook:mounted="$cms.components.onComponentMounted(component)" ></component>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { mixins } from '@fwk-node-modules/vue-class-component';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { mapGetters } from '@fwk-node-modules/vuex';

@Component({
  components: {  },
  computed: {
	...mapGetters({
			currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
			})
  }
})
export default class Cms extends mixins(GenericPage) {

  get components() {
    return this.$cms.components.getComponentsFromRoute(this.$route);
	}

  mounted() {
    this.$root.$emit('triggerScroll');
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior
   */
  afterLeave () {
    this.$root.$emit('triggerScroll');
  }

}
</script>